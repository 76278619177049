import React, { useState } from "react";
import QuestionMiniList from '../components/QuestionMiniList';
import { Helmet } from 'react-helmet';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";
import IsAuthenticated from "../utils/IsAuthenticated";
import Login from './Login';

function Home() {

    const Auth = IsAuthenticated()
    const [flag, setFlag] = useState(true);

    const Secondpage = () => {
        return setFlag(false)
    }

    function QuestionMiniListDisplay() {
        if (flag === true) {
            return <QuestionMiniList id = {1} />
        } else {
            return <QuestionMiniList id = {2} />
        }
    }

    return (
        <>
                {Auth === "Not Authenticated" ? (
                    <Login />
                ) : (
                    <>
                        <Header />

                        <Helmet>
                            <title>diaryhea</title>
                            <meta name="keywords" content="مث بات, diaryhea"></meta>
                        </Helmet>

                        <div className="section">
                            <div className="container">

                                <div className="forum-title">
                                    <h3>چی برامون داری !؟</h3>
                                    <Link className="title-a" to="questions/ask">
                                        <h6>آپلود پست جدید</h6>
                                    </Link>
                                </div>

                                <div className="col-md-3 responsive-box">
                                    <Sidebar />
                                </div>

                                <div className="col-md-9 responsive-box">

                                    {/* <h5 style={flag === true ? {} : {display: 'none'}}>جدید ترین سوالات</h5>
                                    <h5 style={flag === true ? {display: 'none'} : {}}>صفحه دوم سوالات</h5> */}
                                    
                                    <QuestionMiniListDisplay/>
                                    

                                    <div className="col-md-12 col-xs-12">
                                        <div className="row">
                                            <span style={flag === true ? {} : {display: 'none'}}>به دنبال بیشتر هستید؟ <span className="page2" onClick={Secondpage}>صفحه دوم </span></span>                            </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <Footer />
                    </>
                )}

            </>
    );
}

export default Home;