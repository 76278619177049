import React, { useEffect, useState } from 'react';
import Response from '../components/Response';
import { useParams , Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Header from "../components/Header";
import Footer from "../components/Footer";
import UploadComment from '../components/UploadComment';
import Loader from "../components/Loader";
import IsAuthenticated from "../utils/IsAuthenticated";
import Login from './Login';
import config from '../utils/config';

function Questions() {

    const {id} = useParams();    

    const Auth = IsAuthenticated()
    const [data, setdata] = useState([])
    const [creator, setcreator] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const TokenConfig = config();
        TokenConfig.get("https://diaryhea.mathbot.ir/api/posts/" + id + "/").then((res) => {

            setdata(res.data);

            TokenConfig.get(res.data.creator).then((res) => {
                setcreator(res.data)
            })

            setIsLoading(false)

        })

    },[id])

    function SendCommentsLink(){

        let fetchedData = []

        for (const link of data.comments){

            fetchedData.unshift(<Response data={link} />)
        }

        if (fetchedData.length === 0) {
            return (
                <div>
                    <p>هیچ پاسخی موجود نیست. اولین کسی باشید که به این سوال پاسخ می دهد</p>
                </div>
            )
        } else {
            return fetchedData
        }
    }

    return (
        <>
            {Auth === "Not Authenticated" ? (
                <Login />
            ) : (
                <>
                    <Header />

                    {isLoading ? <Loader /> : (

                        <>
                            <Helmet>
                                <title>{data.title}</title>
                            </Helmet>

                            <div className="section">
                                <div className="container">
                                    <div className="col-md-12 responsive-box">

                                    <div className="breadcrumb">
                                        <h6><Link to="/">انجمن</Link> / <Link to={`/questions/${data.id}`}>{data.title}</Link></h6>
                                    </div>
                            
                                    <div className="col-md-12 col-xs-12 responsive-box">
                                        <div className="question-box-big">
                                            <div className="forum-title-QuestionBox">
                                                <h3>{data.title}</h3>
                                                <div className="row question-box-big-details">
                                                    <Link className="username-answer" to={`/users/${creator.username}`}>
                                                        <div className="col-md-4 col-sm-4 col-xs-4 forum-title-ask">
                                                            <div className="account-user-img-box">
                                                                <img src={creator.avatar} className="account-user-img-little" alt={creator.name} />
                                                            </div>
                                                            <h6>{creator.name}</h6>
                                                        </div>
                                                    </Link>
                                                    <div className="col-md-4 col-sm-4 col-xs-4 forum-title-view">
                                                        <h6>{data.created_at}</h6>
                                                    </div>
                                                    <div className="col-md-4 col-sm-4 col-xs-4 forum-title-last-seen">
                                                        <h6>{data.comments.length} پاسخ</h6>
                                                    </div>
                                                </div>
                                            </div>

                                            {data.image != null ? (
                                                <div className="post-img-box">
                                                    <img src={data.image} className="post-img" alt={data.title} />
                                                </div>
                                            ) : (<></>)}
                                            
                                            <div className="post-content-box">
                                                <p>{data.content}</p>
                                            </div>
                                            
                                            <div className="question-tag-box">
                                                {data.tags.length === 0 ? (
                                                    <span style={{fontSize : "12px"}}>بدون برچسب</span>
                                                ) : (
                                                    data.tags.split(",").map((e) => (
                                                        <div className="question-tags">{e}</div>
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="forum-title-questions">
                                        <h3>پاسخ ها</h3>
                                    </div>

                                    {SendCommentsLink()}

                                    <div className="forum-title-questions">
                                        <h3>پاسخ شما</h3>
                                    </div>
                            
                                    <UploadComment postId={id} />

                                </div>

                                </div>
                            </div>
                        </>

                    )}

                    <Footer />
                </>
            )}

        </>
    )
}

export default Questions;