import React from "react";
import IsAuthenticated from "../utils/IsAuthenticated";
import { Link } from "react-router-dom";

function Footer() {
    
    return (
        <div className="footer ">
            <div className="container ">
                <div className="row ">
                    <div className="footerBox">
                        <p>diaryhea</p>
                    </div>
                    <div className="mobile-header">
                        
                        {IsAuthenticated() !== "Not Authenticated" ? (
                            <Link to="/account">
                                <span className="mobile-header-button">
                                        <i className="fa-solid fa-user header-buttons-ico"></i>
                                </span>
                            </Link>     
                        ) : (
                            <Link to="/login">
                                <span className="mobile-header-button">
                                    <i className="fa-solid fa-right-to-bracket header-buttons-ico"></i>
                                </span>
                            </Link>
                        )}

                        <Link to="/">
                            <span className="mobile-header-button">
                                <i className="fa-solid fa-house header-buttons-ico"></i>
                            </span>
                        </Link>

                        <Link to="/questions/ask">
                            <span className="mobile-header-button">
                                <i className="fas fa-plus header-buttons-ico"></i>
                            </span>
                        </Link>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;