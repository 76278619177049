import React from 'react';
import AskBox from '../components/AskBox';
import { Helmet } from 'react-helmet';
import Header from "../components/Header";
import Footer from "../components/Footer";
import IsAuthenticated from "../utils/IsAuthenticated";
import Login from './Login';

function Ask() {

    const Auth = IsAuthenticated()

    return (
        <>
            {Auth === "Not Authenticated" ? (
                <Login />
            ) : (
                <>
                    <Header />

                    <Helmet>
                        <title>Upload</title>
                    </Helmet>

                    <div className="section">
                        <div className="container">
                            <div className="col-md-12 responsive-box">
                                <div className="row ask-your-Q">
                                    <h3>آپلود پست</h3>
                                    <p>از اینجا میتونی پستتو آپلود کنی. دقت کن این پست برای همه کاربر هایی که داخل سایت اکانت دارن قابل نمایشه!</p>
                                </div>

                                <AskBox />

                            </div>

                        </div>
                    </div>

                    <Footer />
                </>
            )}

        </>
    );
}

export default Ask;