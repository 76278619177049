import React from "react";
import IsAuthenticated from "../utils/IsAuthenticated";
import { Link } from "react-router-dom";

function Sidebar() {

    const username = IsAuthenticated()
    
    return (
        <>
            {username === "Not Authenticated" ? (
                <Link to="/login">
                    <div className="sidebar login-text-green">
                        <p>برای آپلود پست و کامنت گذاشتن زیر پستای بقیه باید وارد بشی</p>
                    </div>
                </Link>
            ) : (
                <div className="sidebar">
                    <Link className="sidebar-user-links" to={`/users/${username}`}><p><i class="fa-solid fa-user"></i> پروفایل من</p></Link>
                </div>
            )}
        </>
    );
}

export default Sidebar;