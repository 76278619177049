import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import PostPublicProfile from '../components/PostPublicProfile';
import IsAuthenticated from "../utils/IsAuthenticated";
import Login from './Login';
import config from '../utils/config';

function Users() {

    const {username} = useParams();

    const Auth = IsAuthenticated()
    const [data, setdata] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const TokenConfig = config();
        TokenConfig.get("https://diaryhea.mathbot.ir/api/accounts/" + username + "/").then((res) => {
            setdata(res.data);
            setIsLoading(false)
        })

    },[username])

    function SendPostsLink(){
        const fetchedData = []
        for (const link of data.posts){
            fetchedData.unshift(<PostPublicProfile data={link} />)
        }

        if (fetchedData.length === 0) {
            return (
                <div style={{textAlign: "center"}}>
                    <p>هیچ سوالی توسط <span>{data.name}</span> ثبت نشده است</p>
                </div>
            )
        } else {
            return fetchedData
        }
    }

    if (isLoading) {
        return <Loader />;
    } else {
        return (
            <>
                {Auth === "Not Authenticated" ? (
                    <Login />
                ) : (
                    <>
                        <Header />

                        <Helmet>
                            <title>{data.name}</title>
                        </Helmet>

                        <div className="section">
                            <div className="container">
                                <div style={{marginTop: "50px"}} className="col-md-12 responsive-box">
                                    <div className="account-user-img-box-large">
                                        <img style={{width: "200px", height: "200px"}} src={data.avatar} className="account-user-img" alt={data.name} />
                                    </div>
                                    <div style={{textAlign: "center"}} className="forum-title">
                                        <h3>{data.name} / {data.username}@</h3>
                                        <h4>{data.bio}</h4>
                                    </div>

                                    {SendPostsLink()}

                                </div>

                            </div>
                        </div>

                        <Footer />
                    </>
                )}

            </>
        );
    }
}

export default Users;